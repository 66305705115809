import React, { useEffect, useState, useRef } from "react";
import MasonryList from "../components/MasonryList";
import Layout from "../components/Layout";
import "./Home.css";
import BuyCard from "../components/BuyCard";
function Home() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Layout>
      <BuyCard {...{ setIsOpen, isOpen }} />
      <MasonryList {...{ setIsOpen, isOpen }} />
    </Layout>
  );
}

export default Home;
