import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { readData } from "../api/directus";
import ListItem from "./ListItem";
export default function MasonryList({ setIsOpen }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      let cokeData = await readData();
      console.log("coke", cokeData);
      setData(cokeData.data);
    })();
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
      <Masonry columnsCount={3} gutter="10px">
        {data.length > 0 &&
          data.map((d) => <ListItem key={d.id} {...d} setIsOpen={setIsOpen} />)}
      </Masonry>
    </ResponsiveMasonry>
  );
}
