import React, { useEffect, useState, useRef, Children } from "react";
import { useImageSize } from "react-image-size";
import Footer from "./Footer";
import NavigationBar from "./NavigationBar";
import tw from "twin.macro";
import styled from "styled-components";

const ContentWithPaddingXl = tw.div`max-w-screen-xl mx-auto px-3 md:px-6 py-20 lg:py-24`;

function Layout(props) {
  return (
    <div>
      <NavigationBar />
      <ContentWithPaddingXl>
        {props.children}
        <Footer />
      </ContentWithPaddingXl>
    </div>
  );
}

export default Layout;
